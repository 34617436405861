<template>
  <div>
    <a-row>
      <div class="title custom-flex">
        <div class="icon" />
        <span>厅房数据统计</span>
      </div>
    </a-row>
    <a-row>
      <div class="hall-count">
        <div class="custom-flex custom-items-center custom-justify-center">
          <div>
            <a-icon type="home" :style="{fontSize:'60px',color:'#ffffff'}" />
          </div>
          <div class="content">
            <div>厅房总数</div>
            <div class="count">{{ totalHallsNumber }}</div>
          </div>
        </div>
        <div
          ref="hallDataStatisticsChart"
          class="custom-flex custom-items-center custom-justify-center"
          style="height: 200px; width: 100%;"
        />
      </div>
    </a-row>
  </div>
</template>

<script>
import { findTenantHallDataStatisticsStatistics } from '@/api/home'
export default {
  name: 'HallDataStatistics',
  data() {
    return {
      totalHallsNumber: 0
    }
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        // 提示框
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.marker + this.$lodash.truncate(params.data.name, { length: 6 }) +
                '&nbsp;&nbsp;' + params.value + '间'
          }
        },
        // 图例
        legend: {
          orient: 'vertical',
          right: 15,
          icon: 'circle',
          top: 'middle',
          textStyle: {
            color: '#ffffff',
            fontSize: '15px'
          }

        },
        // 数据组
        series: [
          {
            avoidLabelOverlap: false,
            label: { show: false },
            labelLine: { show: true },
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['0%', '50%'],
            data: [],
            left: 'center'
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findTenantHallDataStatisticsStatistics().then(res => {
        if (res.code === 0) {
          this.totalHallsNumber = res.data.total_number_of_halls
          this.chartData = [
            { value: res.data.free_hall, name: '空闲', itemStyle: { color: '#26a3ff' }},
            { value: res.data.hall_in_use, name: '在用', itemStyle: { color: '#26cece' }},
            { value: res.data.suspend_the_use_of_the_hall, name: '暂停使用', itemStyle: { color: '#cccccc' }}
          ]
          this.drawChart()
        }
      })
    },
    // 绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.hallDataStatisticsChart, 'echarts_custom')
      this.chartOption.series[0].data = this.chartData
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true)
      // 添加表格自适应
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}
.hall-count {
  height: auto;
  border-radius: 10px;
  margin-top: 22px;
  background: linear-gradient(to top right, #1ac1ca, #14cb77);
  padding-top: 20px;

  .content {
    font-size: 15px;
    color: #ffffff;
    margin-left: 20px;

    .count {
      font-size: 30px;
    }
  }
}

</style>

